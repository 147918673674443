import { Typography15, Typography20, useModal, Icon } from "@slid/slid-ips";
import { browserInteractionTime } from "pages/VideoDocumentPage";
import { updateUser } from "redux/actions/slidGlobalActions";
import { showCustomModal } from "utils/modal";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import SavedTimeInfoModal from "../modals/SavedTimeInfoModal";
import * as Sentry from "@sentry/browser";
import { parseUserDataMoreInfo } from "utils/utils";

interface SaveTimeBannerProps {
  width: number;
}

const SavedTimeBanner = ({ width }: SaveTimeBannerProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { userData, userTrialData } = useAppSelector((state) => state.slidGlobal);
  const { showModal, closeModal } = useModal();
  const [totalSavedTime, setTotalSavedTime] = useState<any>();
  const [savedTimeTimer, setSavedTimeTimer] = useState<any>();
  const [isUserDataLoadedOneTime, setIsUserDataLoadedOneTime] = useState<boolean>(false);
  const [usedTrialDays, setUsedTrialDays] = useState<string>("00");
  const [showTrialDaysBanner, setShowTrialDaysBanner] = useState<boolean>(false);

  useEffect(() => {
    // Get the frist total_learning_time when user enter to video note.
    // Since the total_learning_time will be updated every 10sec, we only need the first value to show the accumulated time at first.
    if (!isUserDataLoadedOneTime && userData?.more_info) {
      try {
        const userDataMoreInfo = JSON.parse(userData.more_info) || {};
        if (userDataMoreInfo.total_learning_time) {
          setTotalSavedTime(userDataMoreInfo.total_learning_time);
        } else {
          setShowTrialDaysBanner(true);
          dispatch(
            updateUser({
              data: {
                ...userData,
                more_info: {
                  ...userDataMoreInfo,
                  total_learning_time: 0,
                },
              },
            })
          );
          setTotalSavedTime(0);
        }
      } catch {
        setShowTrialDaysBanner(true);
        setTotalSavedTime(0);
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          scope.setExtra("message", "JSON parse error (user/more_info)");
          Sentry.captureMessage("SLID_WEB_JSON_PARSE_ERROR");
        });
      }
      setIsUserDataLoadedOneTime(true);
    }

    if (userData?.payment === "trial") {
      const originalTrialStartDate = dayjs(userTrialData?.start_date);
      const usedTrialDays = dayjs().diff(originalTrialStartDate, "day") + 1;
      const actualDays = usedTrialDays > 0 ? usedTrialDays : 1;
      setUsedTrialDays(actualDays.toString());
    }
  }, [isUserDataLoadedOneTime, userData, userTrialData]);

  useEffect(() => {
    if (userData?.more_info) {
      const userDataMoreInfo: any = parseUserDataMoreInfo(userData);
      if (!userDataMoreInfo.total_learning_time) {
        setShowTrialDaysBanner(true);
      } else {
        setShowTrialDaysBanner(false);
      }
    }
  }, [userData]);

  useEffect(() => {
    let accumulatedSavedTimeIntervalId: any;
    if (browserInteractionTime.isRunning()) {
      const current = new Date();
      const startPoint = new Date(current.getTime() - totalSavedTime);

      const updateSavedTimeTimer = () => {
        const now = dayjs();
        const leftTrialHours = dayjs(startPoint).diff(now, "hour");
        const leftTrialMinutes = dayjs(startPoint).diff(now, "minute") % 60;
        const leftTrialSeconds = dayjs(startPoint).diff(now, "second") % 60;

        const leftTrialMode = `${("0" + leftTrialHours).slice(-2)}:${("0" + leftTrialMinutes).slice(-2)}:${("0" + leftTrialSeconds).slice(-2)}`;
        setSavedTimeTimer(leftTrialMode);
      };

      accumulatedSavedTimeIntervalId = setInterval(updateSavedTimeTimer, 1000);

      return () => {
        clearInterval(accumulatedSavedTimeIntervalId);
      };
    } else {
      // If the actual timer is stopped, then stop updating the timer on the banner
      clearInterval(accumulatedSavedTimeIntervalId);

      if (userData?.more_info) {
        const userDataMoreInfo: any = parseUserDataMoreInfo(userData);
        if (userDataMoreInfo.total_learning_time === 0) {
          setShowTrialDaysBanner(true);
        }
        if (userDataMoreInfo.total_learning_time) {
          setTotalSavedTime(userDataMoreInfo.total_learning_time);
        }
      }
    }
  }, [totalSavedTime, browserInteractionTime.isRunning()]);

  if (showTrialDaysBanner)
    return (
      <BannerContainer>
        <BannerTextContainer>
          <Icon icon={`sparkles24`} color="--white" />
          <Typography15 weight={700} color="--white" text={t("FreeTrialStartedBannerText", { ns: "Banner" })} />
        </BannerTextContainer>
      </BannerContainer>
    );

  return (
    <ClickableBannerContainer
      onClick={() => {
        trackEvent({ eventType: eventTypes.click.TIME_SAVE_BANNER_IN_VIDEO_NOTE_PAGE });
        showCustomModal({ showModal, closeModal, history, customComponentContent: <SavedTimeInfoModal /> });
      }}
    >
      <BannerTextContainer>
        <Typography20 weight={400} color="--gray17" text="⏰" />
        <Typography15
          weight={400}
          color="--white"
          text={
            width > 400 ? t("TrialModeSavedTimeCardSubTitle", { ns: "CardNotification", day: usedTrialDays }) : t("TrialModeSavedTimeCardSubShortTitle", { ns: "CardNotification", day: usedTrialDays })
          }
        />
      </BannerTextContainer>
      <Typography15 weight={700} color="--white" text={savedTimeTimer} />
    </ClickableBannerContainer>
  );
};

export default SavedTimeBanner;

const BannerContainer = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 36px;
  background-color: var(--blue7);
`;

const ClickableBannerContainer = styled(BannerContainer)`
  background-color: var(--blue6);
  cursor: pointer;
  :hover {
    background-color: var(--blue8);
  }
  :active {
    background-color: var(--blue8);
  }
`;

const BannerTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
