import SavedTimeBanner from "components/banner/SavedTimeBanner";
import StudyChallengeSavedTimeBanner from "components/banner/StudyChallengeSavedTimeBanner";
import { useAppSelector } from "hooks";
import React from "react";

const VideoDocumentEditorBanner = ({ width }: { width: number }) => {
  const { userData, screenOrientation, userStudyChallengeData } = useAppSelector((state) => state.slidGlobal);

  return userData?.payment === "trial" && screenOrientation === "horizontal" && (userStudyChallengeData?.isActive ? <StudyChallengeSavedTimeBanner /> : <SavedTimeBanner width={width || 0} />);
};

export default VideoDocumentEditorBanner;
