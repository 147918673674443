import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { setIsDesktopCaptureAreaSetting } from "redux/actions/vdocsActions";
import { findLatestOfTwoVersions } from "utils/utils";
import { Dim, Typography13 } from "@slid/slid-ips";

const DesktopCaptureAreaSettingModal = ({ openShowSelectWindowModal }: { openShowSelectWindowModal: () => void }) => {
  const { t } = useTranslation();
  const { applicationType, desktopVersion } = useAppSelector((state) => state.slidGlobal);
  const { isDesktopCaptureAreaSetting } = useAppSelector((state) => state.vdocs);
  const dispatch = useAppDispatch();

  if (applicationType === "desktop" && findLatestOfTwoVersions(desktopVersion, "2.5.0") === desktopVersion && isDesktopCaptureAreaSetting)
    return (
      <>
        <Dim type="gray4" closableDim={false} />
        <CaptureAreaSettingContainer>
          <img src="/src/icons/icon_gray_slid_36.svg" alt="" />
          <Typography13 weight={400} color="--gray9" text={t("SettingCaptureAreaMessage", { ns: "Desktop" })} textAlign="center" marginTop="8px" />
          <SelectOtherAppButton
            onClick={() => {
              openShowSelectWindowModal();
              dispatch(setIsDesktopCaptureAreaSetting(false));
            }}
          >
            <Typography13 weight={700} color="--gray9" text={t("SelectAnotherAppButton", { ns: "Desktop" })} />
          </SelectOtherAppButton>
        </CaptureAreaSettingContainer>
      </>
    );
};

const CaptureAreaSettingContainer = styled.div`
  z-index: 99999;
  position: fixed;
  top: calc((100vh - 202px) / 2);
  left: calc((100vw - 284px) / 2);
  width: 284px;
  height: 202px;
  background-color: white;
  border-radius: 8px;
  padding: 40px 52px 32px 52px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectOtherAppButton = styled.div`
  width: auto;
  height: 34px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--gray5);
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default DesktopCaptureAreaSettingModal;
