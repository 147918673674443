// auth 2.0
import React, { useEffect, useRef, useState } from "react";
import "./VideoDocumentEditor.css";
import EditorNoteLoadingScreen from "components/editorLoadingStates/EditorNoteLoadingScreen";
import EditorComponent from "components/editor/EditorComponent";

import DocumentSearch from "../../popups/DocumentSearch";
import DocumentShare from "../../popups/DocumentShare";
import DeletedDocuments from "../../popups/DeletedDocuments";
import DocumentListView from "../../listItem/DocumentListView";
import {
  createDocument,
  updateDocument,
  registerVideo,
  updateCurrentVideo,
  registerClip,
  setIsCaptureAreaActive,
  setIsGettingClipRecordingMediaPermission,
  setCaptureAreaRect, // web
  setIsListOpen,
  setIsHelpMenuOpen,
  setShowSearchPopup,
  setShowSharePopup,
  setShowDeletedDocumentsPopup,
  setEditorWrapperClassName,
  setClipRecordingCountdownNumber,
  setShowDisableMediaRecordingModal,
  setIsExtensionMediaPermitted,
  setIsGettingExtensionMediaPermission,
  setShowExtensionMediaPermissionModal,
  setIsCaptureAreaSetForSnipCapture,
  setCaptureRect,
  setIsCaptureAreaSetForOneClickCapture,
  setSelectableProcesses,
  setIsDesktopCaptureAreaSetting,
  setIsGuestModeAlertShown,
  setIsWebCaptureAvailable,
  setIsSetCaptureAreaButtonClicked,
  setIsDesktopCaptureAreaSet,
} from "redux/actions/vdocsActions";
import { setExtensionVersion } from "redux/actions/slidGlobalActions";
import { setIsGettingSTTMediaPermission, setIsSTTToggledOn, setShowSmartLiveTextView, setStopDesktopSTT } from "redux/actions/sttActions";

import { useHistory, useLocation } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import { InputGroup, FormControl } from "react-bootstrap";
import { isMacOs } from "react-device-detect";
import Sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { checkIfVersionIsAvailable } from "utils/etc/versionChecker";
import { getEventLocationMode } from "utils/utils";
import { sendScreenCaptureRequestToParentWindow, sendVideoInfoRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { uploadBase64Image } from "utils/aws/s3Interface";
import {
  setCheckIsExtensionMediaPermittedResponseListener,
  setCheckIsSlidInstantLaunchedListener,
  setIsExtensionClickedListener,
  setScreenCaptureResponseListener,
  setSetCurrentCaptureAreaResponseListener,
  setSetVideoArrayBufferResponseListener,
  setSnipCaptureIsDoneResponseListener,
} from "utils/extensionInterface/setListenerFromExtension";
import {
  sendResetCaptureAreaForOneClickCaptureRequestToParentWindow,
  sendCancelCaptureAreaRequestToParentWindow,
  sendSetSnipCaptureAreaRequestToParentWindow,
  sendSetFocusOnIframeRequestToParentWindow,
  sendHideVideoRequestToParentWindow,
  sendVideoPlaceholderPositionToParentWindow,
  sendRemoveSnipCaptureAreaRequestToParentWindow,
  sendGoBackExtensionViewerButton,
  sendCheckIsExtensionMediaPermitted,
} from "utils/extensionInterface/sendToExtension";
import * as Sentry from "@sentry/browser";
import { sendAmplitudeData } from "utils/amplitude";
import { getDefaultSettingCookie, setDefaultSettingCookie } from "utils/cookies/cookies";
import { uploadFile } from "utils/aws/s3Interface";
import { v4 as uuidv4 } from "uuid";
import env from "config/env";
import emitter from "react-ab-test/lib/emitter";
import { showDownloadExtensionModal, showFailToLoadWindowModal, showErrorOnDesktopModal, showWantToContinueCapturingModal, showStopCapturingOnDesktopModal, showCustomModal } from "utils/modal";
import { initEventTracking, trackEvent } from "utils/eventTracking";
import { fabric } from "fabric";
import { connectPortToExtension } from "utils/extensionInterface/connectPortToExtension";
import { Icon, Button, Tooltip, useModal, useToast } from "@slid/slid-ips";
import HelpMenu from "components/DropdownMenus/HelpMenu";
import { useTranslation } from "react-i18next";
import DesktopCaptureGuideTooltip from "components/VideoDocument/DesktopCaptureGuideTooltip";
import { PopupContainer, PopupLayer } from "styles";
import styled, { css } from "styled-components";
import SelectWindowModal from "../SelectWindowModal";
import { OneClickIcon } from "components/icons/OneClickIcon";
import ScaleFactorChangeModal from "../ScaleFactorChangeModal";
import ExtensionMediaPermissionModal from "components/VideoDocument/ExtensionMediaPermissionModal";
import { isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";
import DisableMediaRecordingModal from "../../popups/DisableMediaRecordingModal";
import { alertExtensionMediaPermissionGranted } from "components/alerts";
import PermissionGrantedAlertMessage from "components/alerts/PermissionGrantedAlertMessage";
import { SlidFeatures } from "utils/privilegeManager";
import { SliddyIcon } from "components/icons/SliddyIcon";
import SlidGPT from "components/SlidGPT/SlidGPT";
import { eventTypes } from "types/eventTracking";

import { UserMembership } from "types/globals";
import SmartLiveTextVideoNoteButton from "components/SmartLiveText/SmartLiveTextVideoNoteButton";
import WhisperSmartLiveTextVideoNoteButton from "components/SmartLiveTextWhisper/SmartLiveTextVideoNoteButton";
import WhisperRealTimeSmartLiveTextVideoNoteButton from "components/SmartLiveTextWhisperRealTime/SmartLiveTextVideoNoteButton";
import AutoNotesVideoNoteButton from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesVideoNoteButton";
import DesktopSmartLiveTextVideoNoteButton from "../../SmartLiveText/DesktopSmartLiveTextVideoNoteButton";
import { getCurrentDocumentKey, isPreviousBlockEmpty } from "utils/editor/util";
import { VideoNoteStudyChallengeInfo } from "components/myNotes/StudyChallenge";
import VideoDocumentEditorHeader from "./VideoDocumentEditorHeader";
import AutoNotesMainUI from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesMainUI";
import { setIsGettingAutoNotesMediaPermission } from "redux/modules/autoNotesSlice";
import SmartLiveTextProgressInfoView from "components/SmartLiveTextWhisper/SmartLiveTextProgressInfo";
import RealTimeSmartLiveTextProgressInfoView from "components/SmartLiveTextWhisperRealTime/SmartLiveTextProgressInfo";
import WhisperAutoNotesMainUI from "components/smartautonotes/AutoNoteVdocsComponents/WhisperAutoNotesMainUI";
import RealTimeWhisperAutoNotesMainUI from "components/smartautonotes/AutoNoteVdocsComponents/RealTimeWhisperAutoNotesMainUI";
import { useAppDispatch, useAppSelector } from "hooks";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { useChannelTalk } from "hooks/useChannelTalk";
import ImageCroppingModal from "components/ImageCroppingModal/ImageCroppingModal";
import { useMediaQuery } from "react-responsive";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import DesktopCaptureAreaSettingModal from "./DesktopCaptureAreaSettingModal";
import VideoDocumentEditorBanner from "./VideoDocumentEditorBanner";
import VideoDocumentEditorSaveButton from "./VideoDocumentEditorSaveButton";
import VideoRecordButton from "components/VideoCaptureButton/VideoRecordButton";
import { ModalType } from "store/useModalStore";
import { useModalStore } from "store/useModalStore";
import { screenCapture } from "utils/capture/screenCapture";

const ReactSweetalert = withReactContent(Sweetalert);
const CLIP_RECORDING_MAX_SECONDS = 60;
const WEB_CAPTURE_AVAILABLE_EXTENSION_VERSION = "1.6.0";
const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

let shouldRedrawArea;
let mouseDownEventsToIgnore;
let rect;
let initX, initY;
let mouseX, mouseY;
let canvas;

const VideoDocumentEditor: React.FC = (props) => {
  const history = useHistory();
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { bootChannelTalk } = useChannelTalk();
  const { showModal, closeModal } = useModal();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [isCapturing, setIsCapturing] = useState(false);
  const [isSlidInstantLaunched, setIsSlidInstantLaunched] = useState(false);
  const [showDesktopCaptureTooltip, setShowDesktopCaptureTooltip] = useState(true);
  const [shouldShowScaleFactorModal, setShouldShowScaleFactorModal] = useState(false);
  const [isHelpButtonHoveredOnce, setIsHelpButtonHoveredOnce] = useState(false);
  const [openRecentNote, setOpenRecentNote] = useState(getDefaultSettingCookie({ property: "openRecentNote" }) === "true");
  const [recentNoteDocumentKey, setRecentNoteDocumentKey] = useState(null);

  const { applicationType, lang, isExtensionInstalled, extensionVersion, userData, screenOrientation } = useAppSelector((state) => state.slidGlobal);
  const {
    isSetCaptureAreaButtonClicked,
    isDesktopCaptureAreaSetting,
    videoPlayerRef,
    isCaptureAreaSetForSnipCapture,
    isCaptureAreaSetForOneClickCapture,
    editorInstance,
    editorLastActiveBlockPosition,
    currentVideo,
    currentDocument,
    iframePort,
    isGettingExtensionMediaPermission,
    isExtensionMediaPermitted,
    isGettingClipRecordingMediaPermission,
    showExtensionMediaPermissionModal,
    showGuestModeAlert,
    captureRect,
    isListOpen,
    isReadOnly,
    isSTTSupported,
    isHelpMenuOpen,
    showSearchPopup,
    showDeletedDocumentsPopup,
    showSharePopup,
    showDisableMediaRecordingModal,
    isImageCroppingModalOpen,
    loaderBlockKeys,
    isWebCaptureAvailable,
  } = useAppSelector((state) => state.vdocs);
  const { isSTTActive, isGettingSTTMediaPermission, isSTTToggledOn } = useAppSelector((state) => state.sttReducer);
  const { isGettingAutoNotesMediaPermission, isAutoNotesToggledOn, isWhisperAutoNotesSupported, isAutoNotesSupported } = useAppSelector((state) => state.autoNotes);
  const { modalOn } = useAppSelector((state) => state.modal);
  const { isWhisperSLTSupported, isRealTimeTranscriptSupported } = useWhisperSLTStore();
  const { showSliddyChat } = useAppSelector((state) => state.aiSliddy);
  const { showModal: SlidWebShowModal } = useModalStore();

  const searchPopupRef = useRef(null);
  const sharePopupRef = useRef(null);
  const disableMediaRecordingModalRef = useRef(null);
  const deletedDocumentsPopupRef = useRef(null);
  const listContainerRef = useRef(null);
  const desktopAuthLinkInputRef = useRef<HTMLInputElement | null>(null);
  const isDesktopCaptureAreaSetRef = useRef(false);
  const isGettingExtensionMediaPermissionRef = useRef(isGettingExtensionMediaPermission);
  const isExtensionMediaPermittedRef = useRef(isExtensionMediaPermitted);
  const isGettingAutoNotesMediaPermissionRef = useRef(isGettingAutoNotesMediaPermission);
  const isGettingSTTMediaPermissionRef = useRef(isGettingSTTMediaPermission);
  const isGettingClipRecordingMediaPermissionRef = useRef(isGettingClipRecordingMediaPermission);
  const externalPortRef = useRef<any>(null);
  const loaderBlockKeysRef = useRef(loaderBlockKeys);
  const currentDocumentRef = useRef(currentDocument);
  const editorInstanceRef = useRef(editorInstance);
  const editorLastActiveBlockPositionRef = useRef(editorLastActiveBlockPosition);
  const currentVideoRef = useRef(currentVideo);
  const videoPlayerRefRef = useRef(videoPlayerRef);

  const manualCapture = async () => {
    sendAmplitudeData(`SLID_1_CAPTURE_IMAGE`);
    if (applicationType) {
      // Sometimes applicationType is null when the event is occurred in desktop
      trackEvent({
        eventType: "Click 1-click capture",
      });
    }
    if (!confirmPrivilege(SlidFeatures.oneClickCapture)) return showInsufficientPrivilegeModal();
    if (applicationType === "extension") {
      setIsCapturing(true);
      sendScreenCaptureRequestToParentWindow();
    } else if (applicationType === "desktop") {
      if (showGuestModeAlert) return;
      // here the button is blue
      if (isDesktopCaptureAreaSetRef.current) {
        const didSendMessageToPrimary = sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_CAPTURE", payload: null });
        if (didSendMessageToPrimary) {
          setIsCapturing(true);
        } else {
          SlidWebShowModal({
            type: ModalType.DESKTOP_GUIDE_TO_RESTART,
          });
        }
      } else {
        openShowSelectWindowModal();
      }
    } else if (applicationType === "web") {
      if (!isExtensionInstalled) {
        return showDownloadExtensionModal({
          showModal: showModal,
          closeModal: closeModal,
        });
      }
      if (isWebCaptureAvailable) {
        const videoElement =
          document.querySelector(".slid-video-player video") || // normal video
          document.querySelector(".slid-video-player iframe") || // youtube or vimeo
          document.querySelector(".slid-video-player-iframe"); // iframe video
        const rect = {
          top: 2.5,
          left: 2.5,
          width: (videoElement as HTMLElement)?.getBoundingClientRect().width - 5,
          height: (videoElement as HTMLElement)?.getBoundingClientRect().height - 5,
        };
        try {
          externalPortRef.current.postMessage({
            action: "WEB_TO_BACK_captureCurrentArea",
            data: {
              captureRect: captureRect ? captureRect : rect,
              videoContainerRect: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect(),
              devicePixelRatio: window.devicePixelRatio,
            },
          });
        } catch (e) {
          // If web - extension background connection is lost, reload to connect again.
          window.location.reload();
        }
      } else {
        showWantToContinueCapturingModal({
          showModal: showModal,
          closeModal: closeModal,
        });
      }
    }
  };

  /**
   * This method is for receiving keydown event from video which is in slid_extension.
   * @param {event} message
   */
  // TODO: fix type error
  const setMessageListenerFromVideo = (message: MessageEvent) => {
    if (message.data.action === "CONTENT_TO_IFRAME_sendKeyboardShortcutEvent") {
      const shortCutEvent = new Event("keydown");
      const { code, altKey, ctrlKey, metaKey, shiftKey } = message.data.data.event as KeyboardEvent;
      // @ts-ignore
      shortCutEvent.code = code;
      // @ts-ignore
      shortCutEvent.altKey = altKey;
      // @ts-ignore
      shortCutEvent.ctrlKey = ctrlKey;
      // @ts-ignore
      shortCutEvent.metaKey = metaKey;
      // @ts-ignore
      shortCutEvent.shiftKey = shiftKey;
      setShortcutListener(shortCutEvent);
    }
  };

  const openErrorOnDesktopModal = () => {
    showErrorOnDesktopModal({
      showModal,
      closeModal,
    });
  };

  const showGuideMessageForDesktopCaptureFailure = () => {
    ReactSweetalert.fire({
      icon: "info",
      target: ".video-document-container",
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: lang === "ko" ? "캡처 도중 오류" : "Selected app not available!",
      html: (
        <>
          {lang === "ko" ? (
            <p>
              앗! 선택된 프로그램을 찾을 수 없습니다. 😅
              <br />
              슬리드를 재시작해 프로그램을 다시 선택해주세요.
            </p>
          ) : (
            <p>Oops! There was an error capturing, restarting Slid may help.</p>
          )}
          <div className={`mt-3`}>
            <a
              href={`/#`}
              onClick={() => {
                bootChannelTalk();
              }}
            >
              {lang === "ko" ? <>도움이 필요하신가요?</> : <>Need help?</>}
            </a>
          </div>
        </>
      ),
      showConfirmButton: true,
      confirmButtonText: lang === "ko" ? "선택" : "Select",
      showCancelButton: true,
      cancelButtonText: lang === "ko" ? "닫기" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_LAUNCH_PROMPT",
          payload: null,
        });
      }
    });
  };

  const sendMessageToPrimary = (message) => {
    /*
    Sends messages to the primary window of Slid Desktop
    */
    if (iframePort) {
      iframePort.postMessage(message);
      return true;
    } else {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_DESKTOP_IFRAME_DID_NOT_RECEIVE_PORT");
      });
      showGuideMessageForDesktopCaptureFailure();
      return false;
    }
  };

  const captureResponseHandlerWithoutTimestamp = async (imgBase64: string) => {
    const insertCapturedImage = async (currentDocumentKey) => {
      const { blocks, caret } = editorInstanceRef.current;
      const { getCurrentBlockIndex, insert: insertBlock, delete: deleteBlock, getBlockByIndex } = blocks;
      const { setToBlock: setCaretToBlock } = caret;

      /**
       * editorLastActiveBlockPosition is updated in EditorComponent.tsx for store last index of the block when user blurs the editor.
       * When document key is changed by clicking other note in the note list, editorLastActiveBlockPosition becomes undefined so that new image block is inserted at the end of the document.
       */
      let imageInsertIndex;
      let waitTime = 10;
      if (editorLastActiveBlockPositionRef.current !== undefined) {
        imageInsertIndex = editorInstanceRef.current.blocks.getCurrentBlockIndex() === -1 ? editorLastActiveBlockPositionRef.current + 1 : undefined;
      } else {
        waitTime = 200; // wait bit longer when no last active position is available, to set cursor (at least 20ms)
        editorInstance.caret.setToLastBlock("end", 0);
      }

      setTimeout(async () => {
        const element = document.getElementById("slid-editor-js");
        element && element.focus();
        setIsCapturing(true);

        insertBlock(
          "image",
          {
            rawSrc: imgBase64,
            type: "manualCapture",
            setClipData: async () => {
              return await getImageBlockDataWithoutTimestamp(imgBase64, currentDocumentKey);
            },
          },
          undefined,
          imageInsertIndex,
          true
        );

        // delete previous block if it's empty
        const previousBlockIndex = getCurrentBlockIndex() - 1;
        if (isPreviousBlockEmpty(previousBlockIndex)) {
          try {
            deleteBlock(previousBlockIndex);
          } catch (e) {
            // when focus is lost,
            // Uncaught (in promise) TypeError: Cannot read property 'lastInput' of undefined
            // will happen.
            // ignore this for now. it's editor js error.
            // https://github.com/codex-team/editor.js/pull/1218
          }
        }
        const nextBlockIndex = getCurrentBlockIndex() + 1;
        if (!isPreviousBlockEmpty(nextBlockIndex)) {
          insertBlock();
        }
        setCaretToBlock(getCurrentBlockIndex());
        if (getBlockByIndex(getCurrentBlockIndex() - 1)) {
          getBlockByIndex(getCurrentBlockIndex() - 1).holder.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        setIsCapturing(false);
        sendRemoveSnipCaptureAreaRequestToParentWindow();
      }, waitTime);
    };
    if (!editorInstanceRef.current) {
      setIsCapturing(false);
      return;
    }

    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return;
    history.replace(`./${currentDocumentKey}`);
    insertCapturedImage(currentDocumentKey);
  };

  const getImageBlockData = async (receivedData: any, currentDocumentKey: string) => {
    let videoInfo: any = null;
    let hasVideo = true;

    // retry getting video info from extension
    if (applicationType === "extension" && !currentVideoRef.current) {
      sendVideoInfoRequestToParentWindow();
    }

    // upload base64 img to AWS S3
    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: receivedData.captureImgBase64,
    });

    if (!uploadedImageSrc || typeof uploadedImageSrc !== "string") return;

    if (currentVideoRef.current) {
      videoInfo = {
        videoType: currentVideoRef.current.videoType,
        videoUnique: currentVideoRef.current.videoUniqueKey,
        videoOriginUrl: currentVideoRef.current.videoUrl,
        websiteOriginUrl: currentVideoRef.current.originUrl,
        videoKey: currentVideoRef.current.videoKey,
      };

      // if the video key is not here, the video was not yet registered, so register it
      if (!videoInfo.videoKey) {
        const videoRegisterInfo: any = await dispatch(
          registerVideo({
            videoInfo: currentVideoRef.current,
            documentKey: currentDocumentKey,
          })
        );

        if (videoRegisterInfo && !videoRegisterInfo.error_message) {
          videoInfo.videoKey = videoRegisterInfo["video_key"];
          await dispatch(
            updateCurrentVideo({
              videoKey: videoRegisterInfo["video_key"],
            })
          );
          sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);
        }
      }
    }

    // to catch video registration issues in extension
    if (applicationType === "extension") {
      if (!currentVideoRef.current) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video is not set in redux, when saving a capture in extension, no attempt to register video was made");
        });
      }
      if (currentVideoRef.current && !currentVideoRef.current.videoKey) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video key is not set in redux current-video object when saving a capture in extension. Attempt to register video was made but failed");
        });
      }
    }

    // at this point if we still don't have video key, we won't save video info in img block
    if (!videoInfo || !videoInfo.videoKey) hasVideo = false;

    // save the metadata to the database
    const clipRegisterResponse: any = await dispatch(
      registerClip({
        imgSrc: uploadedImageSrc,
        clipTimestamp: receivedData.captureTime,
        documentKey: currentDocumentKey,
        videoKey: hasVideo ? videoInfo?.videoKey : null,
        clipEndPoint: "capture-clip",
      })
    );
    if (clipRegisterResponse.error_message) return;

    // return the image block data
    const imageBlockData: any = {
      src: uploadedImageSrc,
      timestamp: receivedData.captureTime,
      documentKey: currentDocumentKey,
      clipKey: clipRegisterResponse["clip_key"],
      type: "manualCapture",
    };
    if (hasVideo) imageBlockData.videoInfo = videoInfo;
    return imageBlockData;
  };

  const captureResponseHandler = async (receivedData: any) => {
    if (applicationType === "web") {
      if (videoPlayerRefRef.current) {
        // For youtube and vimeo videos
        receivedData = {
          ...receivedData,
          captureTime: videoPlayerRefRef.current.getCurrentTime(),
        };
      }
    }

    const insertCapturedImage = async (currentDocumentKey: string) => {
      const { blocks, caret } = editorInstanceRef.current;
      const { getCurrentBlockIndex, insert: insertBlock, delete: deleteBlock, getBlockByIndex } = blocks;
      const { setToBlock: setCaretToBlock } = caret;

      let imageInsertIndex;
      let firstSLTBlockIndex = -1;
      const lastBlock = blocks.getBlockByIndex(blocks.getBlocksCount() - 1);
      if (isSTTActive) {
        if (lastBlock && lastBlock.name !== "smartLiveText") {
          imageInsertIndex = blocks.getBlocksCount();
        } else {
          const noteData = await editorInstanceRef.current.save();
          noteData.blocks.forEach((block, index) => {
            if (block.type === "smartLiveText" && firstSLTBlockIndex === -1) {
              imageInsertIndex = index;
              firstSLTBlockIndex = index;
              return;
            }
          });
        }
      } else if (editorLastActiveBlockPositionRef.current !== undefined) {
        imageInsertIndex = editorInstanceRef.current.blocks.getCurrentBlockIndex() === -1 ? editorLastActiveBlockPositionRef.current + 1 : undefined;
      } else {
        editorInstanceRef.current.caret.setToLastBlock("end", 0);
      }
      setTimeout(() => {
        const element = document.getElementById("slid-editor-js");
        element && element.focus();
        setIsCapturing(true);

        insertBlock(
          "image",
          {
            rawSrc: receivedData.captureImgBase64,
            timestamp: receivedData.captureTime,
            type: "manualCapture",
            setClipData: async () => {
              return await getImageBlockData(receivedData, currentDocumentKey);
            },
          },
          undefined,
          imageInsertIndex,
          true
        );
        // delete previous block if it's empty
        const previousBlockIndex = getCurrentBlockIndex() - 1;
        if (isPreviousBlockEmpty(previousBlockIndex)) {
          try {
            deleteBlock(previousBlockIndex);
          } catch (e) {
            // ignore this for now. it's editor js error.
          }
        }
        const nextBlockIndex = getCurrentBlockIndex() + 1;
        if (!isPreviousBlockEmpty(nextBlockIndex)) {
          insertBlock();
        }
        setCaretToBlock(getCurrentBlockIndex());
        if (getBlockByIndex(getCurrentBlockIndex() - 1)) {
          getBlockByIndex(getCurrentBlockIndex() - 1).holder.scrollIntoView();
        }
        setIsCapturing(false);
        sendRemoveSnipCaptureAreaRequestToParentWindow();
      });
    };

    if (receivedData.captureImgBase64 === "data:,") {
      showSelectAreaPopup();
      return;
    }
    if (!editorInstanceRef.current) {
      setIsCapturing(false);
      return;
    }
    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return;
    history.replace(`./${currentDocumentKey}`);
    insertCapturedImage(currentDocumentKey);
  };

  const showSelectAreaPopup = () => {
    if (applicationType === "desktop") {
      trackEvent({
        eventType: "Click snip capture",
        eventProperties: {
          depth: applicationType === "desktop" ? (isDesktopCaptureAreaSetRef.current ? 2 : 1) : undefined,
        },
      });
    }
    if (!confirmPrivilege(SlidFeatures.snipCapture)) return showInsufficientPrivilegeModal();
    if (applicationType === "extension") {
      // snip Capture request
      sendSetSnipCaptureAreaRequestToParentWindow();
    } else if (applicationType === "desktop") {
      const didSendCaptureMessageToPrimary = sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_DRAG_AND_DROP_CAPTURE", payload: null });
      if (!didSendCaptureMessageToPrimary) {
        SlidWebShowModal({
          type: ModalType.DESKTOP_GUIDE_TO_RESTART,
        });
        return;
      }
    } else if (applicationType === "web") {
      if (!isExtensionInstalled) {
        return showDownloadExtensionModal({
          showModal: showModal,
          closeModal: closeModal,
        });
      }
      if (isWebCaptureAvailable) {
        // Start snip capture
        dispatch(setIsCaptureAreaSetForSnipCapture(true));
      } else {
        showWantToContinueCapturingModal({
          showModal: showModal,
          closeModal: closeModal,
          history: history,
        });
      }
    }
  };

  const getImageBlockDataWithoutTimestamp = async (imgBase64, currentDocumentKey, fullImageBase64 = null) => {
    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: imgBase64,
    });
    if (!uploadedImageSrc || typeof uploadedImageSrc !== "string") return;

    const clipRegisterResponse: any = await dispatch(
      registerClip({
        imgSrc: uploadedImageSrc,
        clipTimestamp: null,
        documentKey: currentDocumentKey,
        videoKey: currentVideo?.videoKey || null,
        clipEndPoint: "capture-clip",
      })
    );
    if (!clipRegisterResponse) return;

    let imgObj = {
      src: uploadedImageSrc,
      fullSizeImageSrcForCropping: uploadedImageSrc, // For supporting image cropping in desktop app
      documentKey: currentDocumentKey,
      clipKey: clipRegisterResponse["clip_key"],
      type: "manualCapture",
    };

    return imgObj;
  };

  const setKeyDownListener = async (event) => {
    setShortcutListener(event);
    switch (event.key) {
      case "Escape":
        sendCancelCaptureAreaRequestToParentWindow();
        setIsCapturing(false);
        break;
      default:
        break;
    }
  };

  const setShortcutListener = (event) => {
    const code = event.code;
    const states = {
      alt: event.altKey,
      ctrl: event.ctrlKey,
      meta: event.metaKey,
      shift: event.shiftKey,
    };
    switch (code) {
      // short cut for manual capture (Cmd + / or Alt + /)
      case "Slash":
        if (states.alt || states.meta) {
          event.preventDefault();
          if (isCapturing) return;
          manualCapture();
          sendAmplitudeData(`Use shortcut`, {
            type: `one-click capture`,
          });
        }
        break;
      // short cut for snip capture (option + A or Alt + A)
      case "KeyA":
        if (states.alt) {
          event.preventDefault();
          trackEvent({
            eventType: "Use shortcut",
            eventProperties: {
              type: `snip capture`,
            },
          });
          if (applicationType === "extension") {
            if (event.altKey) {
              sendSetSnipCaptureAreaRequestToParentWindow();
            }
          } else if (applicationType === "web") {
            if (!isExtensionInstalled) {
              return showDownloadExtensionModal({
                showModal: showModal,
                closeModal: closeModal,
              });
            }
            if (isWebCaptureAvailable) {
              dispatch(setIsCaptureAreaSetForSnipCapture(true));
            }
          }
        }
        break;
      // short cut for snip capture (option + X or Alt + X)
      case "KeyX":
        if (states.alt) {
          event.preventDefault();
          trackEvent({
            eventType: "Shortcut for setting capture area",
          });
          if (applicationType === "extension") {
            sendResetCaptureAreaForOneClickCaptureRequestToParentWindow();
          } else if (applicationType === "web") {
            if (!isExtensionInstalled) {
              return showDownloadExtensionModal({
                showModal: showModal,
                closeModal: closeModal,
              });
            }
            if (isWebCaptureAvailable) {
              dispatch(setIsCaptureAreaSetForOneClickCapture(true));
            }
          }
        }
        break;
      default:
        return;
    }
  };

  const setExternalPortMessageHandler = () => {
    if (externalPortRef.current) {
      externalPortRef.current.onMessage.addListener((message) => {
        if (message) {
          const { action, data } = message;
          switch (action) {
            case "BACK_TO_WEB_getCurrentVersion":
              const version = data.version;
              dispatch(setExtensionVersion(version));
              break;
            case "BACK_TO_WEB_sendCaptureImg":
              captureResponseHandler(data);
              break;
            default:
              return;
          }
        }
      });
      try {
        externalPortRef.current.postMessage({
          action: "WEB_TO_BACK_getCurrentVersion",
        });
      } catch (e) {
        // If web - extension background connection is lost, reload to connect again.
        window.location.reload();
      }
    }
  };

  const DESKTOP_showGuestModeAlert = () => {
    Sweetalert.fire({
      target: ".video-document-container",
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: lang === "ko" ? "로그인을 먼저 해주세요!" : "Please sign-in!",
      html:
        lang === "ko"
          ? `
              아래 '로그인' 버튼을 누르면 <br/>
              브라우저에서 로그인 창이 열립니다.
          `
          : `
              Click the button below to sign-in. <br/>
              Sign-in page will be opened in the browser.
          `,
      icon: "info",
      confirmButtonText: lang === "ko" ? "로그인" : "Sign-in",
      showCancelButton: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_OPEN_URL",
          payload: `${SLID_WEB_APP_URL}/desktop_auth`,
        });
        ReactSweetalert.fire({
          target: ".video-document-container",
          heightAuto: false,
          customClass: {
            container: "position-absolute",
          },
          title: lang === "ko" ? "새 창에서 로그인을 해주세요!" : "Sign-in in the browser!",
          html: (
            <>
              <p>
                {lang === "ko" ? (
                  <>
                    브라우저에서 로그인창이 뜨지 않으면, <br />
                    아래 주소를 직접 복사해 크롬 주소창에 붙여넣어 주세요.
                  </>
                ) : (
                  <>
                    If sign-in page is not opened, <br />
                    copy and paste the URL to the Chrome.
                  </>
                )}
              </p>
              <div>
                <InputGroup>
                  <FormControl ref={desktopAuthLinkInputRef} value={`${SLID_WEB_APP_URL}/desktop_auth`} readOnly={true} />
                  <Button
                    text="Copy"
                    size="small"
                    appearance="primary"
                    callback={(event) => {
                      onClickCopyBtn(event.target);
                    }}
                  />
                </InputGroup>
              </div>
              <div className={`mt-3`}>
                <a
                  href={`/#`}
                  onClick={() => {
                    bootChannelTalk();
                  }}
                >
                  {lang === "ko" ? <>도움이 필요하신가요?</> : <>Need help?</>}
                </a>
              </div>
            </>
          ),
          showConfirmButton: false,
          allowOutsideClick: false,
        });
      }
    });
  };

  const onClickCopyBtn = (buttonElement) => {
    if (!desktopAuthLinkInputRef.current) return;
    try {
      // copy to clipboard
      desktopAuthLinkInputRef.current.select();
      document.execCommand("copy");
      // Update copy button
      const copiedText = "Copied!";
      const originalText = buttonElement.innerText;
      buttonElement.innerText = copiedText;
      buttonElement.style.color = "#5A95F8";
      // reset
      setTimeout(() => {
        buttonElement.innerText = originalText;
        buttonElement.style.color = "";
      }, 3000);
    } catch (err) {
      // unable to copy
    }
  };

  const setCanvasForSnipCapture = async () => {
    const handleMouseUpForSnipCapture = () => {
      dispatch(setIsCaptureAreaSetForSnipCapture(false));
      const snipCaptureRect = {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      };
      setTimeout(() => {
        // Delay snip capture while removing select area div
        if (externalPortRef.current) {
          try {
            externalPortRef.current.postMessage({
              action: "WEB_TO_BACK_captureCurrentArea",
              data: {
                captureRect: snipCaptureRect,
                videoContainerRect: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect(),
                devicePixelRatio: window.devicePixelRatio,
              },
            });
          } catch (e) {
            // If web - extension background connection is lost, reload to connect again.
            window.location.reload();
          }
        }
        canvas.off("mouse:move", handleMouseMove);
        shouldRedrawArea = false;
      }, 100);
    };
    canvas = new fabric.Canvas("slid-video-snip-capture-area", {
      width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width,
      height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height,
      defaultCursor: "crosshair",
      // @ts-ignore
      id: "snip-capture-canvas",
    });
    shouldRedrawArea = false;
    canvas.selection = false;
    rect = new fabric.Rect({
      left: 0,
      top: 0,
      width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width - 3,
      height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height - 3,
      // @ts-ignore
      id: "selected-area",
      stroke: "#007bff",
      strokeWidth: 3,
      lineWidth: 3,
      strokeDashArray: [6, 3],
      fill: "rgba(255,255,255,0.3)",
      selectable: false,
      hoverCursor: "crosshair",
    });
    canvas.add(rect);
    canvas.on("mouse:down", (options) => {
      // save the initial mouse position
      [initX, initY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      // save mouse position inside the window
      [mouseX, mouseY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      mouseDownEventsToIgnore = 5;
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:up", handleMouseUpForSnipCapture);
    });
  };

  const handleMouseMove = (options) => {
    const generateSelectedArea = () => {
      rect = new fabric.Rect({
        left: mouseX,
        top: mouseY,
        width: 0,
        height: 0,
        // @ts-ignore
        id: "selected-area",
        stroke: "#007bff",
        strokeWidth: 3,
        lineWidth: 3,
        strokeDashArray: [6, 3],
        fill: "rgba(255,255,255,0.3)",
        selectable: false,
        defaultCursor: "crosshair",
      });
      canvas.add(rect);
    };
    if (mouseDownEventsToIgnore > 0) {
      mouseDownEventsToIgnore--;
      return;
    }
    if (!shouldRedrawArea) {
      canvas.getObjects().forEach((object) => {
        if (object.id === "selected-area") {
          canvas.remove(object);
        }
      });
      document.querySelectorAll(".set-default-btn").forEach((btn) => btn.remove());
      document.querySelectorAll(".remove-selected-area-btn").forEach((btn) => btn.remove());
      // generate a new div on the fly
      generateSelectedArea();
      shouldRedrawArea = true;
      return;
    }
    const [newMouseX, newMouseY] = [
      options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left,
      options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top,
    ];
    [mouseX, mouseY] = [newMouseX, newMouseY];
    // check if we can resize
    if (mouseX <= 0) {
      return;
    } else if (mouseX > canvas.width) {
      return;
    }
    if (mouseY <= 0) {
      return;
    } else if (mouseY > canvas.height) {
      return;
    }
    let filteredWidth = Math.abs(mouseX - initX);
    let filteredHeight = Math.abs(mouseY - initY);
    let filteredLeft = rect.left;
    let filteredTop = rect.top;
    if (mouseX > initX) {
      filteredLeft = initX;
    } else {
      filteredLeft = mouseX;
    }
    if (mouseY < initY) {
      filteredTop = mouseY;
    } else {
      filteredTop = initY;
    }
    rect.set({
      width: filteredWidth,
      height: filteredHeight,
      left: filteredLeft,
      top: filteredTop,
    });
    canvas.renderAll();
  };

  const setCanvasForOneClickCapture = () => {
    const addSetDefaultButton = () => {
      const setDefaultBtn = document.createElement("button");
      setDefaultBtn.className = "set-default-btn";
      setDefaultBtn.type = "button";
      setDefaultBtn.innerText = t("ApplyChanges", { ns: "VideoCaptureButton" });
      setDefaultBtn.onclick = () => {
        setDefaultBtn.remove();
        setOneClickCaptureRect();
        dispatch(setIsCaptureAreaSetForOneClickCapture(false));
        document.querySelectorAll(".remove-selected-area-btn").forEach((element) => {
          element.remove();
        });
      };
      const canvasWrapper = document.querySelector("#canvas-wrapper");
      if (canvasWrapper) {
        canvasWrapper.appendChild(setDefaultBtn);
      }
      // Set button position after it is rendered since we can only know the with after it is rendered.
      const buttonWidth = setDefaultBtn.getBoundingClientRect().width;
      setDefaultBtn.style.left = `${rect.left + rect.width + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left - buttonWidth}px`;
      setDefaultBtn.style.top = `${rect.top + rect.height + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top + 10}px`;
    };
    const setOneClickCaptureRect = () => {
      const oneClickCaptureRect = {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      };
      dispatch(setCaptureRect(oneClickCaptureRect));
    };

    const addRemoveSelectedAreaButton = () => {
      const removeSelectedAreaButton = document.createElement("button");
      removeSelectedAreaButton.className = "remove-selected-area-btn";
      removeSelectedAreaButton.type = "button";
      removeSelectedAreaButton.innerText = t("ResetCaptureArea", { ns: "VideoCaptureButton" });
      removeSelectedAreaButton.onclick = () => {
        removeSelectedAreaButton.remove();
        resetOneClickCaptureRect();
        dispatch(setIsCaptureAreaSetForOneClickCapture(false));
        document.querySelectorAll(".set-default-btn").forEach((element) => {
          element.remove();
        });
      };
      const canvasWrapper = document.querySelector("#canvas-wrapper");
      if (canvasWrapper) {
        canvasWrapper.appendChild(removeSelectedAreaButton);
      }
      // Set button position after it is rendered since we can only know the with after it is rendered.
      const buttonWidth = removeSelectedAreaButton.getBoundingClientRect().width;

      const setDefaultButtonWidth = document.querySelector(".set-default-btn")?.getBoundingClientRect()?.width ?? 0;
      removeSelectedAreaButton.style.left = `${rect.left + rect.width + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left - setDefaultButtonWidth - buttonWidth - 10}px`;
      removeSelectedAreaButton.style.top = `${rect.top + rect.height + videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top + 10}px`;
    };

    const resetOneClickCaptureRect = () => {
      const oneClickCaptureRect = {
        left: 0,
        top: 0,
        width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width - 3,
        height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height - 3,
      };
      dispatch(setCaptureRect(oneClickCaptureRect));
    };

    const handleMouseUpForOneClickCapture = () => {
      addSetDefaultButton();
      addRemoveSelectedAreaButton();
      canvas.__eventListeners["mouse:move"] = [];
      canvas.__eventListeners["mouse:up"] = [];
      shouldRedrawArea = false;
    };
    canvas = new fabric.Canvas("slid-video-one-click-capture-area", {
      width: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width,
      height: videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height,
      defaultCursor: "crosshair",
    });
    shouldRedrawArea = false;
    canvas.selection = false;
    rect = new fabric.Rect({
      left: captureRect ? captureRect.left : 0,
      top: captureRect ? captureRect.top : 0,
      width: captureRect ? captureRect.width : videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().width - 3,
      height: captureRect ? captureRect.height : videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().height - 3,
      // @ts-ignore
      id: "selected-area",
      stroke: "#007bff",
      strokeWidth: 3,
      lineWidth: 3,
      strokeDashArray: [6, 3],
      fill: "rgba(255,255,255,0.3)",
      selectable: false,
      hoverCursor: "crosshair",
    });
    canvas.add(rect);
    addSetDefaultButton();
    addRemoveSelectedAreaButton();
    canvas.on("mouse:down", (options) => {
      // save the initial mouse position
      [initX, initY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      // save mouse position inside the window
      [mouseX, mouseY] = [options.e.clientX - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().left, options.e.clientY - videoPlayerRefRef.current?.wrapper?.getBoundingClientRect().top];
      mouseDownEventsToIgnore = 5;
      canvas.on("mouse:move", handleMouseMove);
      canvas.on("mouse:up", handleMouseUpForOneClickCapture);
    });
  };

  const openShowSelectWindowModal = () => {
    if (shouldShowScaleFactorModal) {
      showCustomModal({
        showModal: showModal,
        closeModal: closeModal,
        customComponentContent: <ScaleFactorChangeModal />,
      });
      return;
    }
    // Turn off STT if it's on
    if (isSTTToggledOn) {
      dispatch(setIsSTTToggledOn(false));
      dispatch(setStopDesktopSTT());
      dispatch(setShowSmartLiveTextView(false));
    }
    showCustomModal({
      showModal: showModal,
      closeModal: closeModal,
      customComponentContent: (
        <SelectWindowModal
          setIsDesktopCaptureAreaSetting={(isDesktopCaptureAreaSetting) => {
            dispatch(setIsDesktopCaptureAreaSetting(isDesktopCaptureAreaSetting));
          }}
          setIsDesktopCaptureAreaSet={(isDesktopCaptureAreaSet) => {
            isDesktopCaptureAreaSetRef.current = isDesktopCaptureAreaSet;
          }}
          setShowDesktopCaptureTooltip={(showDesktopCaptureTooltip) => {
            setShowDesktopCaptureTooltip(showDesktopCaptureTooltip);
          }}
        />
      ),
    });
  };

  const saveDocument = async () => {
    if (isReadOnly) return;
    const updatedContents = await editorInstance.save();

    // updating an existing document
    if (currentDocument && currentDocument.document_key !== "new") {
      const updatedDocumentResponse = await dispatch(
        updateDocument({
          documentKey: currentDocument["document_key"],
          title: currentDocument["title"],
          content: updatedContents,
          origin: "vdocs",
        })
      );

      // filter out if video is already registered
      if (applicationType === "extension") {
        if (currentVideo) {
          let isAlreadyRegistered = false;
          currentDocument["mapped_videos"] &&
            currentDocument["mapped_videos"].forEach((mappedVideo) => {
              if (mappedVideo["video_origin_url"] === currentVideo.videoUrl && mappedVideo["website_origin_url"] === currentVideo.originUrl) {
                isAlreadyRegistered = true;
              }
            });
          if (isAlreadyRegistered) return;
          const videoRegisterInfo = await dispatch(
            registerVideo({
              videoInfo: currentVideo,
              documentKey: currentDocument["document_key"],
            })
          );

          if (!videoRegisterInfo || (videoRegisterInfo as any).error_message || !(videoRegisterInfo as any)["video_key"]) {
            Sentry.withScope((scope) => {
              scope.setLevel("error");
              Sentry.captureMessage("When saving existing document in extension, attempt to register video was made but failed.");
            });
            return;
          }
          sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);
          const currentVideoKey = videoRegisterInfo["video_key"];
          await dispatch(
            updateCurrentVideo({
              videoKey: currentVideoKey,
            })
          );
        } else if (!currentVideo) {
          Sentry.withScope((scope) => {
            scope.setLevel("error");
            Sentry.captureMessage("Current video is not set in redux, when saving existing document in extension, no attempt to register video was made.");
          });
        }
      }
    } else {
      // creating a new document
      const createdDocumentResponse = await dispatch(
        createDocument({
          content: updatedContents,
          origin: "vdocs",
        })
      );

      history.replace(`./${currentDocument["document_key"]}`);

      if (applicationType === "extension") {
        if (currentVideo) {
          const videoRegisterInfo = await dispatch(
            registerVideo({
              videoInfo: currentVideo,
              documentKey: currentDocument["document_key"],
            })
          );

          if (!videoRegisterInfo || (videoRegisterInfo as any).error_message || !(videoRegisterInfo as any)["video_key"]) {
            Sentry.withScope((scope) => {
              scope.setLevel("error");
              Sentry.captureMessage("When saving a new document in extension, attempt to register video was made but failed.");
            });
            return;
          }
          sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);
          const currentVideoKey = videoRegisterInfo["video_key"];
          await dispatch(
            updateCurrentVideo({
              videoKey: currentVideoKey,
            })
          );
        }
      } else if (!currentVideo) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video is not set in redux, when saving a new document in extension, no attempt to register video was made. ");
        });
      }
    }
  };

  const renderCaptureBar = (width) => {
    const renderSetCaptureWindowButton = () => {
      if (applicationType === "desktop") {
        return (
          <CaptureButtonPositioner>
            {showDesktopCaptureTooltip && <DesktopCaptureGuideTooltip />}
            <CaptureButton
              // disabled={isCapturing}
              onClick={() => openShowSelectWindowModal()}
              onContextMenu={(event) => {
                event.preventDefault();
              }}
            >
              <Tooltip title={lang === "ko" ? "캡처 앱 변경" : "Set capture app"} placement={"top"}>
                <CaptureButtonOverlayArea></CaptureButtonOverlayArea>
              </Tooltip>

              <img src="/src/icons/icon_select_app.svg" alt="" />
              <ButtonText>{t("SetCaptureWindow", { ns: "VideoNote" })}</ButtonText>
            </CaptureButton>
          </CaptureButtonPositioner>
        );
      }
    };
    const defaultCaptureBar = (
      <CaptureButtonContainer>
        <Tooltip placement={`top`} title={t("SnipCapture", { ns: "VideoCaptureButton" })} description={isMacOs ? "(Option + A)" : "(Alt + A)"}>
          <CaptureButton
            onClick={() => {
              showSelectAreaPopup();
            }}
          >
            <Icon icon={`snip28`} color={`--gray17`} />
            <ButtonText>{t("Snip", { ns: "VideoNote" })}</ButtonText>
          </CaptureButton>
        </Tooltip>
        <CaptureButtonPositioner>
          <CaptureButton
            // disabled={isCapturing}
            onClick={() => {
              if (applicationType === "desktop" && showDesktopCaptureTooltip) setShowDesktopCaptureTooltip(false);
              manualCapture();
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            onMouseUp={(event) => {
              event.preventDefault();
            }}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <Tooltip
              title={
                applicationType === "desktop"
                  ? isDesktopCaptureAreaSetRef.current
                    ? // when button is blue
                      t("1ClickCapture", { ns: "VideoCaptureButton" })
                    : // when button is grey
                      t("SetCaptureArea", { ns: "VideoCaptureButton" })
                  : // etc
                    t("1ClickCapture", { ns: "VideoCaptureButton" })
              }
              description={
                applicationType === "desktop"
                  ? isDesktopCaptureAreaSetRef.current
                    ? // when button is blue
                      isMacOs
                      ? "(Cmd + /)"
                      : "(Alt + /)"
                    : // when button is grey
                      ""
                  : // etc
                    isMacOs
                    ? "(Cmd + /)"
                    : "(Alt + /)"
              }
              placement={"top"}
            >
              <CaptureButtonOverlayArea></CaptureButtonOverlayArea>
            </Tooltip>
            {renderCaptureSettingButton()}
            <Icon icon={`1Click28`} color={`--gray17`} />
            <ButtonText>{t("1Click", { ns: "VideoNote" })}</ButtonText>
          </CaptureButton>
        </CaptureButtonPositioner>
        {applicationType === "desktop" && isSTTSupported && <DesktopSmartLiveTextVideoNoteButton />}
        {renderSetCaptureWindowButton()}
        {applicationType === "extension" && <VideoRecordButton />}

        {applicationType === "extension" && isAutoNotesSupported && currentVideo?.videoType === "youtube" && <AutoNotesVideoNoteButton />}
        {/* TODO: Remove SLT except RealTime after deploying extension 2.7.0 */}
        {applicationType === "extension" && !isWhisperSLTSupported && <SmartLiveTextVideoNoteButton />}
        {applicationType === "extension" && isWhisperSLTSupported && !isRealTimeTranscriptSupported && <WhisperSmartLiveTextVideoNoteButton />}
        {applicationType === "extension" && isWhisperSLTSupported && !isRealTimeTranscriptSupported && <SmartLiveTextProgressInfoView />}
        {applicationType === "extension" && isRealTimeTranscriptSupported && <WhisperRealTimeSmartLiveTextVideoNoteButton />}
        {applicationType === "extension" && isWhisperAutoNotesSupported && !isRealTimeTranscriptSupported && <WhisperAutoNotesMainUI width={width} />}
      </CaptureButtonContainer>
    );

    // For web / extension -> Just show capture bar
    if (applicationType !== "desktop") return defaultCaptureBar;
    // For desktop

    if (applicationType === "desktop") {
      if (isDesktopCaptureAreaSetRef.current) return defaultCaptureBar;
      else return <></>;
    }
  };

  const renderCaptureSettingButton = () => {
    if (applicationType === "desktop") {
      if (!isDesktopCaptureAreaSetRef.current) return;
      return (
        <SettingButtonContainer
          onClick={(e) => {
            e.stopPropagation();
            trackEvent({
              eventType: "Click capture setting button",
            });
            sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_START_SETTING_CAPTURE_AREA", payload: null });
          }}
        >
          <Tooltip title={t("ChangeCaptureArea", { ns: "VideoCaptureButton" })} placement={"top"}>
            <Icon icon={`chevronDown20`} color={`--gray17`} />
          </Tooltip>
        </SettingButtonContainer>
      );
    } else if (applicationType === "extension") {
      return (
        <SettingButtonContainer
          onClick={(e) => {
            e.stopPropagation();
            trackEvent({
              eventType: "Click capture setting button",
            });
            resetCaptureArea();
          }}
        >
          <Tooltip title={t("SetCaptureArea", { ns: "VideoCaptureButton" })} placement={"top"}>
            <div>
              <Icon icon={`chevronDown20`} color={`--gray17`} />
            </div>
          </Tooltip>
        </SettingButtonContainer>
      );
    } else {
      if (!isWebCaptureAvailable) return;
      return (
        <Tooltip title={t("SetCaptureArea", { ns: "VideoCaptureButton" })}>
          <SettingButtonContainer
            onClick={(e) => {
              e.stopPropagation();
              trackEvent({
                eventType: "Click capture setting button",
              });
              dispatch(setIsCaptureAreaSetForOneClickCapture(true));
            }}
          >
            <Icon icon={`chevronDown20`} color={`--gray17`} />
          </SettingButtonContainer>
        </Tooltip>
      );
    }
  };

  const resetCaptureArea = () => {
    sendResetCaptureAreaForOneClickCaptureRequestToParentWindow();
  };

  const onMouseEnterHelpButton = (origin: string) => {
    if (!isHelpMenuOpen && !isHelpButtonHoveredOnce) {
      trackEvent({
        eventType: origin === "help-button" ? eventTypes.hover.HELP_MENU : eventTypes.hover.AI_SLIDDY_BUTTON,
        eventProperties: {
          from: getEventLocationMode(),
        },
      });
      setIsHelpButtonHoveredOnce(true);
    }
    dispatch(setIsHelpMenuOpen(true));
  };

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      /*
      Listen to the messages from the Primary window of Slid Desktop
      */
      switch (event.data.type) {
        case "PRIMARY_TO_IFRAME_ACTIVATE_CAPTURE_BUTTON":
          isDesktopCaptureAreaSetRef.current = true;
          dispatch(setIsDesktopCaptureAreaSetting(false));
          break;
        case "PRIMARY_TO_IFRAME_SPIN_CAPTURE_BUTTON":
          setIsCapturing(true);
          break;
        case "PRIMARY_TO_IFRAME_STOP_SPINNING_CAPTURE_BUTTON":
          setIsCapturing(false);
          break;
        case "PRIMARY_TO_IFRAME_DEACTIVATE_CAPTURE_BUTTON":
          isDesktopCaptureAreaSetRef.current = false;
          break;
        case "PRIMARY_TO_IFRAME_SEND_IMAGE":
          const imgBase64 = event.data.payload;
          captureResponseHandlerWithoutTimestamp(imgBase64);
          break;
        case "PRIMARY_TO_IFRAME_CAPTURE_FAILED":
          SlidWebShowModal({
            type: ModalType.DESKTOP_GUIDE_TO_RESTART,
          });
          setIsCapturing(false);
          break;
        case "PRIMARY_TO_IFRAME_SEND_SELECTABLE_PROCESSES":
          const sources = event.data.payload;
          dispatch(setSelectableProcesses(sources));
          break;
        case "PRIMARY_TO_IFRAME_CANCEL_SETTING_CAPTURE_AREA_FIRST":
          dispatch(setIsDesktopCaptureAreaSetting(false));
          break;
        case "PRIMARY_TO_IFRAME_SELECTED_PROCESS_UNAVAILABLE":
          dispatch(setIsDesktopCaptureAreaSetting(false));
          isDesktopCaptureAreaSetRef.current = false;
          showFailToLoadWindowModal(
            {
              showModal,
              closeModal,
              history,
            },
            openShowSelectWindowModal
          );
          break;
        case "PRIMARY_TO_IFRAME_SELECTED_PROCESS_TERMINATED":
          setIsDesktopCaptureAreaSet(false);
          break;
        case "PRIMARY_TO_IFRAME_SEND_ADI":
          initEventTracking(event.data.payload);
          break;
        case "PRIMARY_TO_IFRAME_handleError":
          dispatch(setIsDesktopCaptureAreaSetting(false));
          isDesktopCaptureAreaSetRef.current = false;
          openErrorOnDesktopModal();
          break;
        case "PRIMARY_TO_IFRAME_showStopCapturingOnDesktopModal":
          showStopCapturingOnDesktopModal({
            showModal,
            closeModal,
          });
          break;
        case "PRIMARY_TO_IFRAME_sendShouldShowScaleFactorModal":
          setShouldShowScaleFactorModal(event.data.payload);
          break;
        case "PRIMARY_TO_IFRAME_trackToggleNSnippersByShortcut":
          trackEvent({
            eventType: "Use shortcut",
            eventProperties: {
              type: `snip capture`,
              isCaptureAreaFixed: isDesktopCaptureAreaSetRef.current,
            },
          });
          break;
        default:
          return;
      }
    };

    sendAmplitudeData(`Enter vdocs page`);
    trackEvent({
      eventType: "Enter video note page",
      eventProperties: location.pathname.includes("new") ? { noteKey: "new" } : {},
    });

    dispatch(setEditorWrapperClassName("video-document-container"));
    document.addEventListener("keydown", setKeyDownListener);
    window.addEventListener("message", onMessage);
    window.addEventListener("message", setMessageListenerFromVideo);

    dispatch(setClipRecordingCountdownNumber(CLIP_RECORDING_MAX_SECONDS));

    setCheckIsSlidInstantLaunchedListener({
      responseHandler: () => {
        setIsSlidInstantLaunched(true);
      },
    });
    setIsExtensionClickedListener({
      responseHandler: () => {
        setIsSlidInstantLaunched(false);
      },
    });

    externalPortRef.current = connectPortToExtension();

    return () => {
      document.removeEventListener("keydown", setKeyDownListener);
      window.removeEventListener("message", onMessage);
      window.removeEventListener("message", setMessageListenerFromVideo);
    };
  }, []);

  useEffect(() => {
    // initialize custom setting cookie
    setOpenRecentNote(getDefaultSettingCookie({ property: "openRecentNote" }) === "true");
    setRecentNoteDocumentKey(getDefaultSettingCookie({ property: "recentNoteDocumentKey" }));
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = await isUserAuthenticated();
      if (applicationType === "desktop" && !isAuthenticated && !showGuestModeAlert) {
        DESKTOP_showGuestModeAlert();
        dispatch(setIsGuestModeAlertShown(true));
      }
    };
    checkAuthentication();
  }, [applicationType]);

  useEffect(() => {
    if (modalOn) {
      if (applicationType === "desktop") {
        dispatch(setIsSetCaptureAreaButtonClicked(true));
      } else if (applicationType === "extension") {
        sendHideVideoRequestToParentWindow();
      }
    } else {
      if (applicationType === "desktop") {
        dispatch(setIsSetCaptureAreaButtonClicked(false));
      } else if (applicationType === "extension") {
        const videoPlaceHolder = document.getElementById("video-placeholder");
        setTimeout(() => {
          sendVideoPlaceholderPositionToParentWindow({
            videoPlaceholderPosition: videoPlaceHolder?.getBoundingClientRect(),
          });
        }, 250);
      }
    }
  }, [modalOn, applicationType]);

  useEffect(() => {
    if (applicationType === "extension") {
      sendGoBackExtensionViewerButton();
      window.parent.postMessage({ action: "IFRAME_TO_CONTENT_checkIsSlidInstantLaunched" }, "*");
      sendCheckIsExtensionMediaPermitted();
      setScreenCaptureResponseListener({
        responseHandler: captureResponseHandler,
      });

      setSetCurrentCaptureAreaResponseListener({
        responseHandler: async (receivedData) => {
          dispatch(
            setCaptureAreaRect({
              top: receivedData.rect.top - receivedData.canvasRect.top,
              left: receivedData.rect.left - receivedData.canvasRect.left,
              width: receivedData.rect.width,
              height: receivedData.rect.height,
            })
          );
          dispatch(setIsCaptureAreaActive(true));
        },
      });

      setSnipCaptureIsDoneResponseListener({
        responseHandler: async () => {
          sendSetFocusOnIframeRequestToParentWindow();
        },
      });
    }
  }, [applicationType]);

  useEffect(() => {
    const checkRecentNoteDocumentKey = async () => {
      if (applicationType === "extension") {
        if (location.pathname === "/vdocs/new" && recentNoteDocumentKey && openRecentNote) {
          history.push(`./${recentNoteDocumentKey}`);
        }
      }
    };
    checkRecentNoteDocumentKey();
  }, [applicationType, recentNoteDocumentKey, openRecentNote]);

  useEffect(() => {
    isGettingExtensionMediaPermissionRef.current = isGettingExtensionMediaPermission;
    isExtensionMediaPermittedRef.current = isExtensionMediaPermitted;
    isGettingAutoNotesMediaPermissionRef.current = isGettingAutoNotesMediaPermission;
    isGettingSTTMediaPermissionRef.current = isGettingSTTMediaPermission;
    isGettingClipRecordingMediaPermissionRef.current = isGettingClipRecordingMediaPermission;
  }, [isGettingExtensionMediaPermission, isExtensionMediaPermitted, isGettingAutoNotesMediaPermission, isGettingSTTMediaPermission]);

  useEffect(() => {
    videoPlayerRefRef.current = videoPlayerRef;
  }, [videoPlayerRef]);

  useEffect(() => {
    editorInstanceRef.current = editorInstance;
  }, [editorInstance]);

  useEffect(() => {
    currentDocumentRef.current = currentDocument;
  }, [currentDocument]);

  useEffect(() => {
    loaderBlockKeysRef.current = loaderBlockKeys;
  }, [loaderBlockKeys]);

  useEffect(() => {
    editorLastActiveBlockPositionRef.current = editorLastActiveBlockPosition;
  }, [editorLastActiveBlockPosition]);

  useEffect(() => {
    if (applicationType === "extension") {
      setIsExtensionClickedListener({
        responseHandler: async () => {
          if (isGettingExtensionMediaPermissionRef.current && !isExtensionMediaPermittedRef.current) {
            if (isGettingAutoNotesMediaPermissionRef.current) {
              setIsGettingAutoNotesMediaPermission(false);
              showToast(t("ToastForPermissionGranted", { ns: "VideoNote" }));
              trackEvent({
                eventType: eventTypes.success.AUTO_NOTES_PERMISSION,
              });
            } else if (isGettingSTTMediaPermissionRef.current) {
              dispatch(setIsGettingSTTMediaPermission(false));
              showToast(t("ToastForPermissionGranted", { ns: "VideoNote" }));
              trackEvent({
                eventType: eventTypes.success.SMART_LIVETEXT_PERMISSION,
              });
            } else if (isGettingClipRecordingMediaPermissionRef.current) {
              alertExtensionMediaPermissionGranted(<PermissionGrantedAlertMessage />);
              trackEvent({
                eventType: eventTypes.success.VIDEO_SNIPPET_PERMISSION,
              });
              dispatch(setIsGettingClipRecordingMediaPermission(false));
            }
            dispatch(setIsExtensionMediaPermitted(true));
            dispatch(setShowExtensionMediaPermissionModal(false));
            dispatch(setIsGettingExtensionMediaPermission(false));
          }
        },
      });

      setSetVideoArrayBufferResponseListener({
        responseHandler: async (receivedData) => {
          if (!currentDocumentRef.current) return;
          let currentDocumentKey = currentDocumentRef.current["document_key"];
          const videoBlob = new Blob([receivedData.buffer], {
            type: "audio/webm;codecs=opus",
          });
          const uploadedVideoUrl = await uploadFile({
            path: `clip_videos/${currentDocumentKey}`,
            file: new File([videoBlob], `${uuidv4()}.webm`),
          });
          const uploadedVideoPosterUrl = await uploadBase64Image({
            path: `clip_video_poster/${currentDocumentKey}`,
            base64: receivedData.posterUrl,
          });
          if (!uploadedVideoPosterUrl || !uploadedVideoUrl) return;
          const savedData = await editorInstanceRef.current.save();
          const loaderBlockKey = loaderBlockKeysRef.current.shift();
          savedData.blocks.forEach((block, index) => {
            if (block.type !== "loader") return;
            if (block.data.blockKey === loaderBlockKey) {
              editorInstanceRef.current.blocks.delete(index);
              editorInstanceRef.current.blocks.insert(
                "video",
                {
                  videoUrl: uploadedVideoUrl,
                  posterUrl: uploadedVideoPosterUrl,
                },
                undefined,
                index,
                true
              );
              editorInstanceRef.current.caret.setToBlock(editorInstanceRef.current.blocks.getCurrentBlockIndex() + 1);
            }
          });
        },
      });

      setCheckIsExtensionMediaPermittedResponseListener({
        responseHandler: async ({ isExtensionMediaPermitted }) => {
          dispatch(setIsExtensionMediaPermitted(isExtensionMediaPermitted));
        },
      });
    }
  }, [applicationType]);

  useEffect(() => {
    if (applicationType === "web" && isExtensionInstalled) {
      externalPortRef.current = connectPortToExtension();
      setExternalPortMessageHandler();
    }
  }, [applicationType, isExtensionInstalled]);

  useEffect(() => {
    if (applicationType === "desktop" && localStorage.getItem("slid_user_set_capture_area") === "true") {
      setShowDesktopCaptureTooltip(false);
    }
  }, [applicationType]);

  useEffect(() => {
    if (currentDocument && applicationType === "extension") {
      setDefaultSettingCookie({
        property: `recentNoteDocumentKey`,
        value: currentDocument["document_key"],
      });
    }
  }, [currentDocument, applicationType]);

  useEffect(() => {
    if (applicationType === "web") {
      const isExtensionVersionAvailable = checkIfVersionIsAvailable({
        requiredVersion: WEB_CAPTURE_AVAILABLE_EXTENSION_VERSION,
        currentVersion: extensionVersion.version,
      });
      if (isExtensionVersionAvailable && externalPortRef.current) {
        dispatch(setIsWebCaptureAvailable(true));
      }
    }
  }, [extensionVersion, applicationType]);

  useEffect(() => {
    if (isCaptureAreaSetForSnipCapture) {
      setCanvasForSnipCapture();
    }
  }, [isCaptureAreaSetForSnipCapture]);

  useEffect(() => {
    if (isCaptureAreaSetForOneClickCapture) {
      setCanvasForOneClickCapture();
    }
  }, [isCaptureAreaSetForOneClickCapture]);

  useEffect(() => {
    if (iframePort) {
      if (userData?.clip_cnt === 0) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_SHOW_HOW_TO_SLID_DESKTOP",
          payload: null,
        });
      } else if (userData) {
        sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_CHECK_SHOULD_SHOW_HOW_TO_SLID_DESKTOP",
          payload: null,
        });
      }
    }
  }, [iframePort, userData]);

  useEffect(() => {
    if (isSlidInstantLaunched) {
      trackEvent({
        eventType: "Enter video note page with instant launch",
      });
    }
  }, [isSlidInstantLaunched]);

  useEffect(() => {
    currentVideoRef.current = currentVideo;
    if (applicationType === "extension" && !currentVideo) {
      sendVideoInfoRequestToParentWindow();
    }
  }, [currentVideo, applicationType]);

  useEffect(() => {
    if (showExtensionMediaPermissionModal) {
      showCustomModal({
        showModal: showModal,
        closeModal: closeModal,
        customComponentContent: <ExtensionMediaPermissionModal />,
      });
    } else {
      closeModal();
    }
  }, [showExtensionMediaPermissionModal]);

  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width }) => {
        return (
          <VideoDocumentContainer className={`video-document-container`}>
            <DesktopCaptureAreaSettingModal openShowSelectWindowModal={openShowSelectWindowModal} />
            <EditorNoteLoadingScreen />
            <ListContainer className={`${isListOpen ? "" : "d-none"}`} ref={listContainerRef}>
              <DocumentListView />
            </ListContainer>
            <VideoDocumentEditorHeader saveDocument={saveDocument} />
            <VideoDocumentEditorBanner width={width || 0} />
            <VideoNoteStudyChallengeInfo />

            <EditorContainer
              applicationType={applicationType}
              id={`editor-container`}
              slid-cy={`vdocs-editor-container`}
              isHorizontal={screenOrientation !== "vertical"}
              onClick={(event) => {
                if (isListOpen && listContainerRef.current && !(listContainerRef.current as any).contains(event.target)) {
                  dispatch(setIsListOpen(false));
                }
              }}
            >
              <EditorVerticalScrollWrapper>{applicationType && <EditorComponent />}</EditorVerticalScrollWrapper>
            </EditorContainer>
            {screenOrientation !== "vertical" && (
              <>
                <VideoDocumentEditorSaveButton width={width || 0} saveDocument={saveDocument} />
                {applicationType === "extension" && isRealTimeTranscriptSupported && <RealTimeSmartLiveTextProgressInfoView />}
                {applicationType === "extension" && isRealTimeTranscriptSupported && <RealTimeWhisperAutoNotesMainUI />}
                {renderCaptureBar(width)}

                {applicationType === "desktop" && !isDesktopCaptureAreaSetRef.current && (
                  <DesktopCaptureBarContainer>
                    <Tooltip title={t("StartSnipCaptureButtonTooltip", { ns: "Desktop" })} placement={`top`} description={isMacOs ? "(Option + A)" : "(Alt + A)"}>
                      <div>
                        <DesktopSnipCaptureButton
                          appearance="line"
                          size="medium"
                          isDisabled={isSetCaptureAreaButtonClicked || isDesktopCaptureAreaSetting}
                          isLoading={!iframePort}
                          icon={<Icon icon={`snip28`} height={24} width={24} color={"--gray9"} />}
                          text={t("StartSnipCaptureButton", { ns: "Desktop" })}
                          color={`--gray9`}
                          style={{ borderColor: `var(--gray4)`, backgroundColor: "var(--white)" }}
                          callback={() => {
                            trackEvent({ eventType: "Click START SNIP CAPTURING in video note" });
                            if (!confirmPrivilege(SlidFeatures.snipCapture)) return showInsufficientPrivilegeModal();
                            showSelectAreaPopup();
                          }}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title={t("StartCaptureButtonTooltip", { ns: "Desktop" })} placement={`top`} description={isMacOs ? "(Cmd + /)" : "(Alt + /)"}>
                      <div>
                        <SetCaptureAreaButton
                          appearance="primary"
                          size="medium"
                          isDisabled={isSetCaptureAreaButtonClicked || isDesktopCaptureAreaSetting}
                          isLoading={!iframePort}
                          icon={<OneClickIcon height={24} width={24} color={isSetCaptureAreaButtonClicked || isDesktopCaptureAreaSetting ? "var(--gray5)" : "var(--white)"} />}
                          text={t("StartCaptureButton", { ns: "Desktop" })}
                          callback={() => {
                            trackEvent({ eventType: "Click START CAPTURING in video note" });
                            if (!confirmPrivilege(SlidFeatures.oneClickCapture)) return showInsufficientPrivilegeModal();
                            openShowSelectWindowModal();
                          }}
                        />
                      </div>
                    </Tooltip>
                  </DesktopCaptureBarContainer>
                )}
                {width && width > 410 && !(userData?.payment === UserMembership.break) ? (
                  <BetaPateHelpButtonContainer onMouseEnter={() => onMouseEnterHelpButton("sliddy-button")}>
                    <SliddyIcon color={`white`} width={40} height={40} />
                  </BetaPateHelpButtonContainer>
                ) : (
                  width &&
                  width > 570 && (
                    <HelpButtonContainer onMouseEnter={() => onMouseEnterHelpButton("help-button")}>
                      <Icon icon={`help8`} color={`--gray13`} width={8} height={15} />
                    </HelpButtonContainer>
                  )
                )}
              </>
            )}
            {isAutoNotesToggledOn && !isWhisperAutoNotesSupported && <AutoNotesMainUI width={width || 0} />}
            {showSliddyChat && <SlidGPT />}
            {isHelpMenuOpen && <HelpMenu isSliddyAvailable={!(userData?.payment === UserMembership.break) && screenOrientation !== "vertical"} />}
            {showSearchPopup && (
              <PopupLayer
                isMobile={isMobile}
                onClick={(event) => {
                  if (!(searchPopupRef.current as any).contains(event.target)) {
                    dispatch(setShowSearchPopup(false));
                  }
                }}
              >
                <PopupContainer ref={searchPopupRef}>
                  <DocumentSearch />
                </PopupContainer>
              </PopupLayer>
            )}
            {showDeletedDocumentsPopup && (
              <PopupLayer
                isMobile={isMobile}
                onClick={(event) => {
                  if (!(deletedDocumentsPopupRef.current as any).contains(event.target)) {
                    dispatch(setShowDeletedDocumentsPopup(false));
                  }
                }}
              >
                <PopupContainer ref={deletedDocumentsPopupRef}>
                  <DeletedDocuments />
                </PopupContainer>
              </PopupLayer>
            )}
            {showSharePopup && (
              <PopupLayer
                isMobile={isMobile}
                onClick={(event) => {
                  if (!(sharePopupRef.current as any).contains(event.target)) {
                    dispatch(setShowSharePopup(false));
                  }
                }}
                style={{ backgroundColor: `rgba(33, 37, 41, 0.5)` }}
              >
                <PopupContainer ref={sharePopupRef}>
                  <DocumentShare />
                </PopupContainer>
              </PopupLayer>
            )}
            {showDisableMediaRecordingModal && (
              <PopupLayer
                onClick={(event) => {
                  if (!(disableMediaRecordingModalRef.current as any).contains(event.target)) {
                    dispatch(setShowDisableMediaRecordingModal(false));
                  }
                }}
                style={{ backgroundColor: `rgba(33, 37, 41, 0.5)` }}
              >
                <PopupContainer ref={disableMediaRecordingModalRef}>
                  <DisableMediaRecordingModal />
                </PopupContainer>
              </PopupLayer>
            )}
            {isImageCroppingModalOpen && <ImageCroppingModal />}
          </VideoDocumentContainer>
        );
      }}
    </ReactResizeDetector>
  );
};

// Called when the experiment is displayed to the user.
emitter.addPlayListener((experimentName, variantName) => {
  sendAmplitudeData(`Enter vdocs onboarding`, {
    experiment: experimentName,
    type: variantName,
  });
});

const VideoDocumentContainer = styled.div`
  height: 100%;
  max-width: 100%;
  background-color: $bg-color-white;
  min-height: -webkit-fill-available;
  position: relative;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;

  button {
    touch-action: manipulation;
  }
`;

const EditorContainer = styled.div<{ applicationType: string; isHorizontal: boolean }>`
  flex: 1 1 auto;
  padding-left: 20px;

  ${(props) =>
    props.applicationType !== "desktop" &&
    css`
      @media screen and (max-width: 799px) {
        padding-top: 42px;
      }
    `}

  ${(props) =>
    props.applicationType === "desktop" &&
    css`
      padding-top: 24px;
    `}

  ${(props) =>
    props.isHorizontal &&
    css`
      overflow-y: auto;
      overflow-x: hidden;
    `}
`;

const DesktopSnipCaptureButton = styled(Button)`
  && {
    display: flex;
    z-index: 1;
    filter: drop-shadow(var(--boxShadow1));

    :hover {
      background-color: var(--gray4) !important; // Without important, inline style (white background) is prioritized.
      -webkit-transition: background-color 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out;
    }
  }
`;

const SetCaptureAreaButton = styled(Button)`
  display: flex;
  z-index: 1;
  filter: drop-shadow(var(--boxShadow1));
`;

const BetaPateHelpButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 26px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HelpButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 32px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--gray4);
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }
`;

const SettingButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 6px;
  width: 22px;
  height: 24px;
  border-radius: 8px;

  &:hover {
    background-color: var(--gray5);
  }
`;

const ButtonText = styled.span`
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--gray17);
`;

const CaptureButtonOverlayArea = styled.div`
  position: absolute;
  left: 0px;
  top: 0;
  width: 80px;
  height: 52px;
`;

const CaptureButton = styled.div`
  // S of reset button
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding: 0;
  position: relative;

  &:focus {
    outline: 0;
    border: none;
  }
  // E of reset button

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 84px;
  height: 52px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }

  &:active {
    background-color: var(--gray3);
  }
`;

const CaptureButtonPositioner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CaptureButtonContainer = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 4px 0;
  z-index: 1;
`;

const DesktopCaptureBarContainer = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  gap: 10px;
  bottom: 20px;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  z-index: 1;
`;

const EditorVerticalScrollWrapper = styled.div`
  max-width: 850px;
  margin: 0 auto;
`;

const ListContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
`;

export default VideoDocumentEditor;
