import { useAppDispatch, useVideoRecord } from "hooks";
import { useAppSelector } from "hooks";
import React from "react";
import { setShowDisableMediaRecordingModal } from "redux/actions/vdocsActions";
import styled from "styled-components";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { setMediaRecordingChangeDirection } from "redux/actions/vdocsActions";
import { Icon } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";

const VideoRecordButton = () => {
  const { isRecordActive } = useAppSelector((state) => state.vdocs);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { isSTTToggledOn } = useAppSelector((state) => state.sttReducer);
  const { isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);
  const { clipRecordStart, clipRecordStop } = useVideoRecord();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <CaptureButton
      onClick={() => {
        trackEvent({ eventType: eventTypes.click.CLIP_RECORDING_IN_VIDEO_NOTE_PAGE });
        if (!confirmPrivilege(SlidFeatures.clipRecording)) return showInsufficientPrivilegeModal();
        if (isSTTToggledOn || isAutoNotesToggledOn) {
          if (isSTTToggledOn) {
            dispatch(setMediaRecordingChangeDirection("SLT_TO_SNIPPET"));
          }
          if (isAutoNotesToggledOn) {
            dispatch(setMediaRecordingChangeDirection("AUTO_NOTES_TO_SNIPPET"));
          }
          dispatch(setShowDisableMediaRecordingModal(true));
          return;
        }
        if (isRecordActive) {
          clipRecordStop();
        } else {
          clipRecordStart();
        }
      }}
    >
      {!isRecordActive ? <Icon icon={`recording28`} color={`--gray17`} /> : <Icon icon={`usingRecording28`} color={`--red7`} />}
      <ButtonText>{!isRecordActive ? t("Record", { ns: "VideoNote" }) : t("Recording", { ns: "VideoNote" })}</ButtonText>
    </CaptureButton>
  );
};

const CaptureButton = styled.div`
  // S of reset button
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding: 0;
  position: relative;

  &:focus {
    outline: 0;
    border: none;
  }
  // E of reset button

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 84px;
  height: 52px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }

  &:active {
    background-color: var(--gray3);
  }
`;

const ButtonText = styled.span`
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: var(--gray17);
`;

export default VideoRecordButton;
